<template>
	<div>
		<p>The list of plan items</p>
		<base-table :items="currentPlan.items" table-top paginate class="shadow-none border-secondary"></base-table>
	</div>
</template>

<script>
import BaseTable from '@/components/BaseTable.vue';

export default {
	name: 'PlanDetails',

	components: { BaseTable },

	props: {
		currentPlan: {
			type: Object,
			required: true,
		},
	},
};
</script>

<style lang="scss" scoped></style>
