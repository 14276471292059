<template>
	<div v-if="!loading">
		<base-table :items="plans" :headers="plansHeaders" table-top paginate>
			<template #cell(actions)="data">
				<b-button variant="outline-secondary" class="cursor-pointer" size="sm" @click="openDetails(data.item)">
					<span class="align-middle">View </span>
				</b-button>
			</template>
		</base-table>

		<b-modal v-model="showDetails" title="Plan details" size="lg" hide-footer centered>
			<Details :current-plan="currentPlan" />
		</b-modal>
	</div>
	<div v-else class="">
		<div class="text-center text-primary mt-3 mb-2">
			<b-spinner class="align-middle" style="width: 3rem; height: 3rem;"></b-spinner>
			<span class="d-block mt-1">Loading...</span>
		</div>
	</div>
</template>

<script>
import { BModal, BSpinner, BButton } from 'bootstrap-vue';
import BaseTable from '@/components/BaseTable.vue';
import store from '@/store';
import Details from './Details.vue';

export default {
	name: 'Plans',

	components: { BaseTable, BModal, BSpinner, BButton, Details },

	data: () => ({
		plans: [],

		plansHeaders: [
			{ key: 'id', label: 'ID', sortable: true },
			{ key: 'from_date', sortable: true },
			{ key: 'to_date', sortable: true },
			{ key: 'actions' }
		],

		loading: false,
		showDetails: false,
		currentPlan: null
	}),

	async created() {
		this.loading = true;

		try {
			this.plans = await store.dispatch('purchases/getPlans', this.$route.params.id);
			this.loading = false;
		} catch (error) {
			console.log(error);
			this.loading = false;
		}
	},

	methods: {
		openDetails(plan) {
			this.currentPlan = plan;
			this.showDetails = true;
		}
	}
};
</script>

<style lang="scss" scoped></style>
